<template>
  <basic-layout title="Admin">
    <b-row>
      <b-col cols="12">
        <users-management-table />
      </b-col>
    </b-row>
  </basic-layout>
</template>

<script>
import BasicLayout from "../layouts/BasicLayout.vue";
import UsersManagementTable from "../components/tables/UsersManagementTable.vue";
export default {
  name: "ANP-admin",
  components: { BasicLayout, UsersManagementTable },
};
</script>

<style>
</style>