<template>
  <div class="row no-gutters users-management-table">
    <div class="col-12 d-flex align-items-center">
      <h3>Users management</h3>
      <div class="margin-left-auto action-container">
        <simple-form-input
          type="text"
          v-model="query"
          :label="$t('general.searchHere')"
          v-debounce:300ms="handleSearchQuery"
        >
          <template v-slot:leadingIcon>
            <search-icon />
          </template>
        </simple-form-input>
      </div>
    </div>
    <div class="table-container col-12">
      <b-table
        :items="users"
        :fields="fields"
        show-empty
        :busy="isLoading"
        class="no-border-last-row"
        id="user-management-table"
      >
        <template #cell(tier)="data">
          <b-button v-b-modal.change-tier-modal @click="setEditUser(data)">
            {{ data.value }}
            <cog-icon :size="14" />
          </b-button>
        </template>
        <template v-slot:table-busy>
          <div class="table-loader">
            <b-spinner class="spinner"></b-spinner>
            <span>{{ $t("general.loading") }}...</span>
          </div>
        </template>
        <template #empty>
          <div class="table-no-data">
            <info-icon />
            <span>{{ $t("general.noData") }} </span>
          </div>
        </template>
      </b-table>
      <b-pagination
        aria-controls="user-management-table"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="usersCount"
        pills
        size="sm"
        align="center"
        @change="handlePageChange"
      />
    </div>
    <change-tier-modal :editUser="editUser" @submit="handleChangeTier" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ChangeTierModal from "../modals/ChangeTierModal.vue";
import CogIcon from "vue-material-design-icons/Cog.vue";
import InfoIcon from "vue-material-design-icons/InformationOutline.vue";
import SearchIcon from "vue-material-design-icons/Magnify.vue";
import SimpleFormInput from "../inputs/SimpleFormInput.vue";
import { formatDateToCommon } from "../../utils/helper";
export default {
  name: "users-management-table",
  components: {
    CogIcon,
    InfoIcon,
    SearchIcon,
    SimpleFormInput,
    ChangeTierModal,
  },
  data() {
    return {
      fields: [
        {
          key: "username",
        },
        {
          key: "email",
        },
        {
          key: "tier",
          formatter: (val) => this.$t(`subscription.tierNames.${val}`),
        },
        {
          key: "entity",
        },
        {
          key: "date_joined",
          label: "Registration date",
          formatter: (val) => formatDateToCommon(val),
        },
      ],
      isLoading: true,
      perPage: 10,
      currentPage: 1,
      query: "",
      editUser: null,
    };
  },
  methods: {
    ...mapActions("adminStore", ["getUsers", "setTier"]),
    handlePageChange(page) {
      this.isLoading = true;
      this.getUsers({
        page_size: this.perPage,
        page,
        email: this.query,
      }).then(() => (this.isLoading = false));
    },
    handleSearchQuery() {
      this.isLoading = true;
      this.getUsers({
        page_size: this.perPage,
        page: 1,
        email: this.query,
      }).then(() => (this.isLoading = false));
    },
    setEditUser(data) {
      this.editUser = data.item;
    },
    handleChangeTier(data) {
      this.setTier(data)
        .then(() => {
          this.$toast.success("Tier changed");
        })
        .catch(() => this.$toast.error("Error"))
        .finally(() => (this.isChangingTier = false));
    },
  },
  mounted() {
    this.getUsers({
      page_size: this.perPage,
      page: 1,
    }).finally(() => (this.isLoading = false));
  },
  computed: {
    ...mapGetters("adminStore", ["users", "usersCount"]),
  },
};
</script>

<style lang="scss" scoped>
.users-management-table {
  .cog-icon {
    cursor: pointer;
    margin-left: 10px;
  }
}
</style>