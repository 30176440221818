<template>
  <b-modal id="change-tier-modal" @show="handleShow">
    <validation-observer v-slot="{ handleSubmit }">
      <form
        id="choose-plan-form-admin"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <validation-provider rules="required" v-slot="{ errors, failed }">
          <select-box
            :options="billingCycleOptions"
            label="name"
            v-model="billingCycle"
            :hasError="failed"
            :helperText="errors[0]"
            :clearable="false"
            :searchable="false"
            :name="$t('subscription.order.billingCycle')"
          />
        </validation-provider>
        <validation-provider rules="required" v-slot="{ errors, failed }">
          <select-box
            :options="PRODUCTS"
            label="name"
            v-model="product"
            :hasError="failed"
            :helperText="errors[0]"
            :name="$t('subscription.order.tier')"
            :clearable="false"
            :searchable="false"
          />
        </validation-provider>
      </form>
    </validation-observer>
    <template #modal-footer="{ cancel, ok }">
      <b-button variant="primary" @click="cancel"> Cancel </b-button>
      <b-button type="submit" form="choose-plan-form-admin" @click="ok">
        Activate tier
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BILLING_CYCLE_OPTIONS, PRODUCTS } from "../../utils/constants";
import SelectBox from "../inputs/SelectBox.vue";
export default {
  components: { SelectBox },
  name: "change-tier-modal",
  props: {
    editUser: {
      type: Object,
    },
  },
  data() {
    return {
      product: PRODUCTS[0],
      billingCycleOptions: BILLING_CYCLE_OPTIONS(),
      billingCycle: BILLING_CYCLE_OPTIONS()[0],
      PRODUCTS,
    };
  },
  methods: {
    onSubmit() {
      this.$emit("submit", {
        tier: this.product.id,
        tier_period: this.billingCycle.id,
        id: this.editUser.id,
      });
    },
    handleShow() {
      this.product = PRODUCTS.find(
        (product) => product.id === this.editUser.tier
      );
    },
  },
};
</script>

<style>
</style>