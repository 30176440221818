<template>
  <sidebars-layout>
    <h1>Edit report</h1>
    <vue-editable-grid
      id="edit-report-grid"
      ref="grid"
      :column-defs="columnDefs"
      :row-data="activeReport.report_details"
      row-data-key="ticker"
    >
    </vue-editable-grid>
    <div class="mt-3 d-flex justify-content-end">
      <b-button @click="handleSave" :disabled="isUpdating">
        <b-spinner v-if="isUpdating" small />
        Save
      </b-button>
    </div>
  </sidebars-layout>
</template>

<script>
import MainAlert from "../components/alerts/MainAlert.vue";
import SidebarsLayout from "../layouts/SidebarsLayout.vue";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  components: { SidebarsLayout },
  name: "EditReport",
  data() {
    return {
      columnDefs: [
        { field: "id", headerName: "ID", editable: false },
        {
          sortable: true,
          filter: true,
          field: "symbol",
          headerName: "Ticker",
          editable: false,
        },
        {
          sortable: true,
          filter: true,
          field: "name",
          headerName: "Name",
          editable: false,
        },
        {
          sortable: true,
          filter: true,
          field: "direction",
          headerName: "Direction",
          editable: false,
        },
        {
          sortable: true,
          filter: true,
          field: "score",
          headerName: "Score",
          editable: true,
        },
      ],
      isUpdating: false,
    };
  },
  computed: {
    ...mapGetters("stockpickingLabStore", {
      activeReport: "activeDetail",
    }),
  },
  methods: {
    handleSave() {
      const data = this.$refs.grid.getFormattedRows().map((row) => ({
        ...row,
        direction: row.direction.toUpperCase(),
        score: parseFloat(row.score),
      }));
      const changedRows = data.filter(
        (row, i) => this.activeReport.report_details[i].score !== row.score
      );
      this.isUpdating = true;
      axios
        .patch(`sp/Report/${this.$route.params.id}`, {
          report_details: changedRows,
        })
        .then(() => {
          this.$toast.success({
            component: MainAlert,
            props: {
              message: "Updated sucessfully",
            },
          });
        })
        .catch(() => {
          this.$toast.success({
            component: MainAlert,
            props: {
              message: "Error",
            },
          });
        })
        .finally(() => (this.isUpdating = false));
    },
  },
};
</script>

<style>
</style>